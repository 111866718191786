import {
  styled,
  motion,
  Img,
  Image,
  colors,
  breakpoints,
  typo,
} from "../../styles/base/_allBase.styles";

// Breakpoints
const {
  xxSmallDevices,
  xxSmallDevicesLandscape,
  extraSmallDevices,
  extraSmallDevicesLandscape,
  mediumDevices,
  mediumDevicesLandscape,
  largeDevices,
  extraLargeDevices,
  xxLargeDevices,
} = breakpoints;

export const HeaderDiv = styled(motion.div)`
  margin: 0rem 0rem;
  padding: 0rem 0rem;
  position: ${(props) => (props.position ? props.position : "fixed")};
  top: 0rem;
  left: 0rem;
  z-index: 100;
  height: 10vh;
  max-height: 10vh;
  display: grid;
  width: 100%;
  grid-template-columns: 0.5fr 1fr 0.5fr;
  grid-template-rows: 1fr;
  gap: 0px 2px;
  grid-template-areas: "left center right";
  @media ${xxSmallDevicesLandscape} {
  }
  @media ${extraSmallDevices} {
  }
  @media ${mediumDevices} {
  }
  @media ${largeDevices} {
  }
  @media ${extraLargeDevices} {
  }
  @media ${xxLargeDevices} {
  }
`;

export const LeftGridItem = styled(motion.div)`
  grid-area: left;
  justify-self: start;
  align-self: center;
  padding-left: 2rem;
  display: flex;
  align-items: center;
  gap: 1rem;
  @media ${extraSmallDevices} {
    align-items: start !important;
    justify-items: start !important;
    justify-self: start !important;
    margin: auto 0rem;
  }
  @media ${mediumDevices} {
    display: inline-block;
  }
  @media ${largeDevices} {
    display: inline-block;
    padding-left: 6rem;
  }
  @media ${extraLargeDevices} {
    display: inline-block;
  }
  @media ${xxLargeDevices} {
    display: inline-block;
  }
`;

export const CenterGridItem = styled(motion.div)`
  grid-area: center;
  justify-self: center;
  align-self: center;
  height: auto;
  @media ${xxSmallDevices} {
    display: inline-block;
  }
  @media ${xxSmallDevicesLandscape} {
  }
  @media ${extraSmallDevices} {
  }
  @media ${extraSmallDevices} {
  }
  @media ${extraSmallDevicesLandscape} {
  }
  @media ${mediumDevices} {
  }
  @media ${largeDevices} {
  }
  @media ${extraLargeDevices} {
  }
  @media ${xxLargeDevices} {
  }
`;

export const RightGridItem = styled(motion.div)`
  grid-area: right;
  align-self: center;
  margin: auto auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  justify-items: center;
  align-items: center;
  @media ${xxSmallDevices} {
    align-items: end !important;
    justify-items: end !important;
    justify-self: end !important;
    margin: auto 1rem;
  }
  @media ${xxSmallDevicesLandscape} {
    justify-items: end;
    align-items: center;
    justify-self: end;
    margin: 0rem 0.5rem;
  }
  @media ${extraSmallDevices} {
    align-items: end !important;
    justify-items: end !important;
    justify-self: end !important;
    margin: auto 2rem;
  }
  @media ${extraSmallDevicesLandscape} {
    justify-items: end;
    align-items: center;
    justify-self: end;
    margin: 0rem 2rem;
  }
  @media ${mediumDevices} {
    align-items: end !important;
    justify-items: end !important;
    justify-self: end !important;
    margin: auto 4rem;
  }
  @media ${largeDevices} {
    align-items: end !important;
    justify-items: end !important;
    justify-self: end !important;
    margin: auto 4rem;
  }
  @media ${extraLargeDevices} {
  }
  @media ${xxLargeDevices} {
  }
`;

export const ImgFullLogo = styled(motion(Image))`
  width: auto;
  height: 3rem;
  ${"" /* overflow: hidden; */}
  padding: 0rem 0rem;
  cursor: pointer;
  margin: auto auto;
  justify-items: center !important;
  justify-self: center !important;
  @media ${xxSmallDevices} {
    height: 2rem;
  }
  @media ${xxSmallDevicesLandscape} {
    height: 2rem;
  }
  @media ${extraSmallDevices} {
  }
  @media ${mediumDevices} {
  }
  @media ${largeDevices} {
    height: 4.2rem;
  }
  @media ${extraLargeDevices} {
    height: 4.2rem;
  }
  @media ${xxLargeDevices} {
    height: 4.2rem;
  }
`;

export const ItemDiv = styled(motion.div)`
  display: flex;
  align-items: center;
  gap: 1rem;
  cursor: pointer;
`;
