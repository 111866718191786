import Boniberry from "./boni-berry.svg";
import bookNow from "./book-now.svg";
import BookNowSVG from "./book-now.jsx";
import Facebook from "./facebook.svg";
import FacebookIcon from "./facebook";
import Instagram from "./instagram.svg";
import InstagramIcon from "./instagram.jsx";
import sunRise from "./sun-rise.svg";
import tikTok from "./tiktok.svg";
import twinPeaks from "./twin-peaks.svg";
import twitter from "./twitter.svg";
import bbLogo from "./bblogo.svg";
import Bed from "./bed.svg";
import Ethernet from "./ethernet.svg";
import Fridge from "./fridge.svg";
import Occupants from "./occupants.svg";
import RoomSize from "./room-size.svg";
import Shower from "./shower.svg";
import Television from "./television.svg";
import Wifi from "./wifi.svg";
import TripAdvisor from "./tripadvisor.svg";
import TripAdvisorIcon from "./tripadvisor.jsx";
import whatsaAppLogo from "./whatsapp.svg";
const socialsArray = [Instagram, Facebook, TripAdvisor];
const socialsArrayCompSVG = [InstagramIcon, FacebookIcon, TripAdvisorIcon];
const roomAmenityArray = [
  Bed,
  Ethernet,
  Shower,
  Fridge,
  Occupants,
  RoomSize,
  Television,
  Wifi,
];
export {
  bbLogo,
  Boniberry,
  bookNow,
  BookNowSVG,
  Facebook,
  Instagram,
  sunRise,
  tikTok,
  twinPeaks,
  twitter,
  socialsArray,
  socialsArrayCompSVG,
  roomAmenityArray,
  whatsaAppLogo,
};
