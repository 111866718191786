import React, { useState, useEffect } from "react";
import { useRouter } from "next/router";
import fullLogo from "../../assets/logo/BB_Logotype-01.png";
import Link from "next/link";
// Context
import { useMenu } from "../../context/menu.context";
// Components
import NavigationHamburger from "../navigation/navigation";
import { MenuHamburger } from "../hamburger/hamburger-menu";
// Animations
import {
  headerTypography,
  logoAnimation,
  headerScrollAnimation,
} from "../../styles/base/_animations.styles";
// Assets
import BookNowSVG from "../../assets/svg/book-now.svg";
// Styles
import {
  motion,
  useScroll,
  base,
  Image,
} from "../../styles/base/_allBase.styles";
const { MenuP, MenuPBookNow } = base;
import {
  HeaderDiv,
  ImgFullLogo,
  MotionLi,
  LeftGridItem,
  CenterGridItem,
  RightGridItem,
  ItemDiv,
} from "./header.styles";
import { directBookingLink } from "../../lib/companyInfo.js";

// Functions
import { createEventOptions } from "../../lib/libFunctions";

function Header({ logo = fullLogo, isMenu = false, position = "fixed" }) {
  const { isOpen, toggle } = useMenu();
  const [scroll, setScroll] = useState(0);
  const { scrollY } = useScroll();
  useEffect(() => {
    return scrollY.onChange((latest) => {
      setScroll(latest);
    });
  }, []);

  const router = useRouter();
  const { slug } = router.query;

  function handleSendEventClick(slug) {
    const eventName = "booking_click_header";
    const eventOptions = createEventOptions(slug);

    gtag("event", eventOptions[eventName].event, {
      event_category: eventOptions[eventName].category,
      event_label: eventOptions[eventName].label,
      page_path: eventOptions[eventName].page,
    });

    gtag("event", "conversion", {
      send_to: "AW-11381423161/iri1COixhvIYELn4irMq",
    });
  }

  return (
    <HeaderDiv
      position={position}
      style={{ display: "grid" }}
      name={"header-main-div"}
      {...headerScrollAnimation(scroll)}
    >
      {!isMenu && <NavigationHamburger isOpen={isOpen} />}
      <LeftGridItem>
        <ItemDiv onClick={() => toggle()} {...headerTypography}>
          <MenuHamburger isOpen={isOpen} />
          <MenuP>{!isOpen ? "Menu" : "Close"}</MenuP>
        </ItemDiv>
      </LeftGridItem>
      <CenterGridItem>
        <Link name={"Link to home page"} href={"/"}>
          <ImgFullLogo
            {...logoAnimation}
            alt={"Boni Beach Boutique Hotel and Restaurant Logo "}
            src={logo}
          />
        </Link>
      </CenterGridItem>
      <RightGridItem>
        <a href={directBookingLink} onClick={() => handleSendEventClick(slug)}>
          <ItemDiv {...headerTypography}>
            <MenuPBookNow>Book Now</MenuPBookNow>
            <MenuP>
              {/* <Image
                // src={bookNowSVG}
                alt={"icon for Book now link for Boni Beach Lombok"}
              > */}
              <BookNowSVG />
              {/* </Image> */}
            </MenuP>
          </ItemDiv>
        </a>
      </RightGridItem>
    </HeaderDiv>
  );
}

export { Header };
