import React from "react";

const BookNowSVG = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="62"
    height="20"
    viewBox="0 0 62 20"
  >
    <defs>
      <pattern
        id="pattern"
        preserveAspectRatio="none"
        width="100%"
        height="100%"
        viewBox="0 0 284 93"
      >
        <image
          width="284"
          height="93"
          xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAARwAAABdCAYAAAB3h9mdAAAACXBIWXMAACE3AAAhNwEzWJ96AAAETklEQVR4nO3djW3bOBgGYKboAB7BG5xH8AgZISNkhGyQEYyboLdBchMkGxQ3gbuBDwmkwlVJ6scyK4vPAxAIUDtIWOMF+Yn8chdYg30IYRdC2Jz9Lq/NALjYNoRwCCEcQwinxDg2r9mabmCqh56giY1Hsw2MdRgZNOfjYLaBoR4vCJt2PJttoM+uJ0R2Z+//+Pops+3am20g5yUSHG+doOnaNq/pvu+7mQZSYqubY+cxeMqmCZju++/NNhDzFAmMhxEztVdABobqbqeOE2auu8p5MfuU9sWML8qQLdKH9wk/dPfUscIxxQmc5fjY4ny74k/z361NCOsjcJbh0NRk9lcs5v51a5MCzK97cvh7ZGv1LVL0Hbr9anXP5KjhUJwVzp91iDxt2kbuPf0b+SnH3I16iATUlDoQcKP67kSdH+jbDnhNyi5x4njIe4EVeBhw56m7tYoF1LGn5rNPhI3tFFRiSNi04/yp1TZzN+rlrOi8b76OXYWwuoGK5MImFRDnJ4LvJ94Qn3pCGbhRubBpi7qxi5an5mpDa8wKSdhAhfrCppUq8J46fWx2mXBK1YOcLIYK5PrYxFYcsYuWse1VGFCrebOqgXoMXbF09dV6Yof+9p0x9mAgcMNyYTOkNUQudI62SEDr0rBp9RWID1YyULe5wqaV+37taudp2LcC1mQzc9i0Ur2Ju0+iFIihErlzNHNdJYi1Gu0OtR1YuVzYvM1cZ3l2sA/qVTJscmd0hA2sXMmwyRWPFY2hAksIG3/uBSqQaqAVaxF6CWEDlUuFzXHmXjPCBipXKmxyDbeEDVSgVNjkitHX/NtVwEIsIWzmLkYDC5S6RClsgFkN7dZ3KWEDlRM2QBGlwiZk6kPCBiqwhLCZuz4ELNDYpueXEDZQsalNz6cQNlCxktcIhA1UrGTYPAobqFfJsMkVo+99BmHdrtX0PKbkky9gYUo0PW8JG6pXs5Ine4UNVEzTc6AITc+BYjQ9B4rQ9BwoQtNzoAhNz4EiND0Hikj90X99iIFZaXoOFJGrpehDDMzqRdgAJaSuEmh6DswuFgRz3/zWrQ/4FAuCOUNA2ACfUtupuc7BCBvgp6dEIJyaMLqEsAF+kXo6dbrwgqam58BvUmHTjik9aDQ9B36T66x3Pp5HrHS0BgWicvWb2Paqr6YjbICkXP1m7GondelT2ACfUnenhqx22sLvLnNdQdgAn3YTw6Z7Vif378IG+JR6bD3XEDbAT32rk6njOMOBQWBlptZv+rZYbn0Dv5ijfjP2kTlQqaEH/vrGm1oNXN/XG5/jHxe89z2E8BpC+Lv5GqBX7uxMd7t0aFYy6jPAJLsmTLoBc2yKv49NY3XgD7tbyX/Aprm93QbLP7ZJsDxrCRyYYqOnUVE/7q7QZHxJfKBgOV6/OncClPLFTAOlCBygGIEDFCNwgGIEDlCMwAGKufXLm3CpVzNYzPtdBedwfKBgCUII/wOB9o0N+gfqJQAAAABJRU5ErkJggg=="
        />
      </pattern>
    </defs>
    <rect
      id="Book_NowAsset_2_3x"
      data-name="Book NowAsset 2@3x"
      width="62"
      height="20"
      fill="url(#pattern)"
    />
  </svg>
);

export default BookNowSVG;
