import image from "../assets/images/overPool.jpg";
import imagePool from "../assets/images/overPool.jpg";
import roomSuites from "../assets/images/roomsSuites.jpeg";
import food from "../assets/images/food1.jpg";
import rinjani from "../assets/images/mountRingani.jpeg";
import bookNow from "../assets/images/bookNow.jpeg";
import contact from "../assets/images/contact.jpeg";

import { directBookingLink } from "./companyInfo";

const text =
  "A widespread hotel in the heart of Sannio Beneventano, a microcosm of pleasure, beauty and well-being.";
const label = [
  "Boni Beach",
  "Rooms & Suites",
  "Guest Services",
  "How to React Us",
];

export const navigation = {
  home: {
    id: "00",
    header: "Hospitality",
    text: "Located in the Selong Belanak Bay Boni Beach is one of Lombok's premiere venues",
    image: imagePool,
    label: [
      { header: "Home", path: "/" },
      { header: "Boni Beach", path: "/about-boni-beach-lombok" },
      {
        header: "Rooms & Suites",
        path: "luxury-hotel-rooms-lombok",
      },
    ],
    path: "/",
  },
  about: {
    id: "01",
    header: "Boni Beach Lombok",
    text: "Enjoy Lombok from Boni Beaches Luxurious comfort",
    image: image,
    label: [
      { header: "About Boni Beach", path: "/about-boni-beach-lombok" },
      { header: "Explore Lombok", path: "/explore-lombok" },
      { header: "A Boni Good Day", path: "/a-good-day" },
    ],
    path: "/about-boni-beach-lombok",
  },
  rooms: {
    id: "02",
    header: "Rooms",
    text: "Modern Design, relaxing palettes and luxurious finishes",
    image: roomSuites,
    path: "/luxury-hotel-rooms-lombok",
    label: [
      {
        header: "Pool Side Rooms",
        path: `/luxury-hotel-rooms-lombok/pool-side-suites`,
      },
      {
        header: "Bungalows",
        path: `/luxury-hotel-rooms-lombok/bungalow`,
      },
      {
        header: "Deluxe Bungalow",
        path: `/luxury-hotel-rooms-lombok/deluxe-bungalow`,
      },
      {
        header: "Villa",
        path: `/luxury-hotel-rooms-lombok/villa`,
      },
    ],
  },
  restaurant: {
    id: "03",
    header: "Dine",
    text: "Enjoy one of Lombok's more creative dining experiences",
    image: food,
    label: [
      {
        header: "Restaurant at Boni Beach",
        path: "/restaurant",
      },
      {
        header: "The Rooftop Bar",
        path: `/boni-beach-bar-lounge`,
      },
    ],
    path: "/restaurant",
  },

  explore: {
    id: "05",
    header: "Explore Lombok",
    text: "Lombok's natural beauty ",
    image: rinjani,
    path: "/explore-lombok",
    label: [
      {
        header: "Explore Lombok",
        path: `explore-lombok`,
      },
      { header: "A Boni Good Day", path: "/what-to-do-in-lombok" },
      // {
      // 	header: 'Surfing',
      // 	path: `explore-lombok/surfing`,
      // },
      // {
      // 	header: 'Natural Beauty',
      // 	path: `explore-lombok/natural-beauty`,
      // },
    ],
  },
  book: {
    id: "06",
    header: "Book Your Stay",
    text: "Book one of our four room styles to create your tropical getaway",
    image: bookNow,
    path: directBookingLink,
    label: [
      {
        header: "Stay With Us",
        path: directBookingLink,
      },
    ],
  },
  contact: {
    id: "07",
    header: "Get in Touch",
    text: "For our best prices contact us directly",
    image: contact,
    path: "/contact",
    label: [
      {
        header: "Contact",
        path: `/contact`,
      },
      // {
      // 	header: 'Socal Media',
      // 	path: `/contact`,
      // },
    ],
  },
};

export const structuredNavArray = [
  { ...navigation.home },
  // { ...navigation.about },
  { ...navigation.rooms },
  { ...navigation.restaurant },
  // { ...navigation.hospitality },
  { ...navigation.explore },
  { ...navigation.book },
  { ...navigation.contact },
];
