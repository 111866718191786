import React from "react";

const InstagramIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="55.128"
    height="55.116"
    viewBox="0 0 55.128 55.116"
  >
    <path
      id="Icon_awesome-instagram"
      data-name="Icon awesome-instagram"
      d="M27.565,15.665A14.131,14.131,0,1,0,41.7,29.8,14.109,14.109,0,0,0,27.565,15.665Zm0,23.318A9.187,9.187,0,1,1,36.752,29.8a9.2,9.2,0,0,1-9.187,9.187Zm18.005-23.9a3.3,3.3,0,1,1-3.3-3.3A3.288,3.288,0,0,1,45.57,15.087Zm9.359,3.345c-.209-4.415-1.218-8.326-4.452-11.548S43.344,2.653,38.929,2.431c-4.55-.258-18.19-.258-22.74,0-4.4.209-8.314,1.218-11.548,4.44S.41,14,.188,18.42c-.258,4.55-.258,18.19,0,22.74C.4,45.575,1.406,49.486,4.641,52.708s7.133,4.231,11.548,4.452c4.55.258,18.19.258,22.74,0,4.415-.209,8.326-1.218,11.548-4.452s4.231-7.133,4.452-11.548c.258-4.55.258-18.177,0-22.728Zm-5.879,27.61a9.3,9.3,0,0,1-5.239,5.239c-3.628,1.439-12.237,1.107-16.246,1.107s-12.631.32-16.246-1.107a9.3,9.3,0,0,1-5.239-5.239C4.641,42.414,4.973,33.805,4.973,29.8s-.32-12.631,1.107-16.246A9.3,9.3,0,0,1,11.319,8.31C14.947,6.871,23.556,7.2,27.565,7.2S40.2,6.883,43.812,8.31a9.3,9.3,0,0,1,5.239,5.239c1.439,3.628,1.107,12.237,1.107,16.246S50.49,42.426,49.051,46.042Z"
      transform="translate(0.005 -2.238)"
    />
  </svg>
);

export default InstagramIcon;
