import React from "react";

const FacebookIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="29.749"
    height="55.544"
    viewBox="0 0 29.749 55.544"
  >
    <path
      id="Icon_awesome-facebook-f"
      data-name="Icon awesome-facebook-f"
      d="M29.409,31.244l1.543-10.052H21.306V14.668c0-2.75,1.347-5.431,5.667-5.431h4.385V.679A53.474,53.474,0,0,0,23.574,0C15.631,0,10.439,4.815,10.439,13.53v7.661H1.609V31.244h8.83v24.3H21.306v-24.3Z"
      transform="translate(-1.609)"
    />
  </svg>
);

export default FacebookIcon;
