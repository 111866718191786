import {
  styled,
  motion,
  Img,
  colors,
  breakpoints,
  typo,
} from "../../styles/base/_allBase.styles";

// Breakpoints
const {
  xxSmallDevices,
  xxSmallDevicesLandscape,
  extraSmallDevices,
  extraSmallDevicesLandscape,
  mediumDevices,
  mediumDevicesLandscape,
  largeDevices,
  extraLargeDevices,
  xxLargeDevices,
} = breakpoints;

export const GridContainer_Page_Layout = styled(motion.div)`
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: repeat(3, auto);
  gap: 0px 0px;
  grid-template-areas:
    "header"
    "content"
    "footer";
  background: ${colors.warmPalette.secondary};
`;

export const MenuStructureContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 92vw;
  height: auto;
  gap: 2rem;
  margin: 4vh 4vw;
  border-bottom: 0.25rem solid ${colors.warmPalette.primary};
  @media ${xxSmallDevices} {
  }
  @media ${xxSmallDevicesLandscape} {
  }
  @media ${extraSmallDevices} {
  }
  @media ${extraSmallDevicesLandscape} {
  }
  @media ${mediumDevices} {
  }
  @media ${mediumDevicesLandscape} {
  }
  @media ${largeDevices} {
  }
  @media ${extraLargeDevices} {
  }
  @media ${xxLargeDevices} {
  }
`;

export const GridItem1 = styled.div`
  display: grid;
  grid-template-rows: 10vh 30vh 20vh;
  width: 80%;
  @media ${xxSmallDevices} {
  }
  @media ${xxSmallDevicesLandscape} {
  }
  @media ${extraSmallDevices} {
  }
  @media ${extraSmallDevicesLandscape} {
  }
  @media ${mediumDevices} {
  }
  @media ${mediumDevicesLandscape} {
  }
  @media ${largeDevices} {
  }
  @media ${extraLargeDevices} {
  }
  @media ${xxLargeDevices} {
  }
`;

export const GridItem2 = styled.div`
  padding: 6rem 2rem;

  @media ${xxSmallDevices} {
  }
  @media ${xxSmallDevicesLandscape} {
  }
  @media ${extraSmallDevices} {
  }
  @media ${extraSmallDevicesLandscape} {
  }
  @media ${mediumDevices} {
  }
  @media ${mediumDevicesLandscape} {
  }
  @media ${largeDevices} {
  }
  @media ${extraLargeDevices} {
  }
  @media ${xxLargeDevices} {
  }
`;

export const MotionLi = styled(motion.li)`
  align-text: center;
  top: 0;
  left: 0;
  bottom: 0;
  cursor: pointer;
  z-index: 2;
`;

export const MotionUl = styled(motion.ul)`
  padding: 0rem 0rem;
  z-index: 1;
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: start;
  padding: 2rem 0rem;
  height: 80%;
  position: relative;
`;

export const FooterContainer = styled.div`
  height: 30vh;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr 1.5fr;
  margin: 2rem 6rem;
  @media ${xxSmallDevices} {
  }
  @media ${xxSmallDevicesLandscape} {
  }
  @media ${extraSmallDevices} {
  }
  @media ${extraSmallDevicesLandscape} {
  }
  @media ${mediumDevices} {
  }
  @media ${mediumDevicesLandscape} {
  }
  @media ${largeDevices} {
  }
  @media ${extraLargeDevices} {
  }
  @media ${xxLargeDevices} {
  }
`;

export const SocialContainer = styled.div`
  justify-self: end;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 1fr;
  width: 40%;
  height: 50%;
  margin: 0rem 4rem;
  gap: 1rem;
  @media ${xxSmallDevices} {
  }
  @media ${xxSmallDevicesLandscape} {
  }
  @media ${extraSmallDevices} {
  }
  @media ${extraSmallDevicesLandscape} {
  }
  @media ${mediumDevices} {
  }
  @media ${mediumDevicesLandscape} {
  }
  @media ${largeDevices} {
  }
  @media ${extraLargeDevices} {
  }
  @media ${xxLargeDevices} {
  }
`;

export const PrivacyContainer = styled.div`
  justify-self: start;
  display: grid;
  grid-template-rows: repeat(4, 1fr);
  grid-template-columns: 1fr;
  width: auto;
  height: 80%;
  margin: 0rem 0rem;
  gap: 0.75rem;
  @media ${xxSmallDevices} {
  }
  @media ${xxSmallDevicesLandscape} {
  }
  @media ${extraSmallDevices} {
  }
  @media ${extraSmallDevicesLandscape} {
  }
  @media ${mediumDevices} {
  }
  @media ${mediumDevicesLandscape} {
  }
  @media ${largeDevices} {
  }
  @media ${extraLargeDevices} {
  }
  @media ${xxLargeDevices} {
  }
`;
export const BookNowContainer = styled(motion.div)`
  justify-self: end;
  align-self: end;
  display: grid;
  grid-template-columns: 3fr 1fr;
  grid-template-rows: 1fr;
  width: 80%;
  height: 20%;
  margin: 1rem 0rem;
  gap: 0.75rem;
  cursor: pointer;
  @media ${xxSmallDevices} {
  }
  @media ${xxSmallDevicesLandscape} {
  }
  @media ${extraSmallDevices} {
  }
  @media ${extraSmallDevicesLandscape} {
  }
  @media ${mediumDevices} {
  }
  @media ${mediumDevicesLandscape} {
  }
  @media ${largeDevices} {
  }
  @media ${extraLargeDevices} {
  }
  @media ${xxLargeDevices} {
  }
`;

export const MotionP = styled(motion.p)`
  height: auto;
  font-size: 2rem;
  width: 100%;
  cursor: pointer;
  text-align: right;
  margin: 0rem;
  @media ${extraSmallDevices} {
  }
  @media ${mediumDevices} {
  }
  @media ${largeDevices} {
  }
  @media ${extraLargeDevices} {
  }
  @media ${xxLargeDevices} {
  }
`;

export const NavImage = styled(motion.div)`
  height: 65%;
  width: auto;

  @media ${extraSmallDevices} {
  }
  @media ${mediumDevices} {
  }
  @media ${largeDevices} {
  }
  @media ${extraLargeDevices} {
  }
  @media ${xxLargeDevices} {
  }
`;
